<template>
  <v-container fluid>
    <NewCalendar v-if="broadcastResponse" />
    <v-row justify="center">
      <v-col
        v-for="(campus, key) in getters_total_student_campus.student_campus"
        :key="key"
        cols="12"
        md="2"
        lg="2"
      >
        <v-card
          :elevation="key + 9"
          tile
          :color="`light-blue darken-${key}`"
          dark
        >
          <v-card-title class="py-1">{{ campus.campus_name }} </v-card-title>
          <v-card-text class="py-0">
            <div class="subtitle-1 font-weight-normal">
              Total Students
              <b class="float-right">
                {{
                  campus.students.reduce((acc, curr) => {
                    return acc + curr.total;
                  }, 0)
                }}
              </b>
            </div>
            <v-divider></v-divider>
            <v-btn text @click="(reveal = true), thisCampus(campus.id)">
              Details
            </v-btn>
          </v-card-text>

          <v-expand-transition>
            <v-card
              v-if="reveal && campus_id === campus.id"
              class="transition-fast-in-fast-out v-card--reveal"
              style="height: 100% !important"
              :color="`blue-grey darken-${key}`"
              tile
            >
              <v-card-text>
                <template v-for="(gender, index) in campus.students">
                  <div :key="index" class="subtitle-2 font-weight-normal">
                    <span v-if="gender.gender.toLowerCase() == 'female'">
                      Female
                    </span>
                    <span v-else-if="gender.gender.toLowerCase() == 'male'">
                      Male
                    </span>
                    <div class="float-sm-right">
                      <v-avatar tile size="20">
                        {{ gender.total }}
                      </v-avatar>
                    </div>
                  </div>
                </template>
                <v-btn class="card-actions" text @click="reveal = false">
                  Close
                </v-btn>
              </v-card-text>
            </v-card>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="2" lg="2">
        <v-card elevation="9" tile>
          <v-card-text>
            <v-row align="center">
              <v-col cols="8" lg="8">
                <div>
                  <v-icon x-large color="secondary">
                    mdi-account-group-outline
                  </v-icon>
                </div>
                <div class="subtitle-1 font-weight-normal">Staff</div>
              </v-col>
              <!-- <v-divider vertical class="my-4"></v-divider> -->
              <v-col
                cols="4"
                lg="4"
                class="text-center headline font-weight-bold"
              >
                {{ getters_total_student_campus.total_staff }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="2" lg="2">
        <v-card elevation="9" tile>
          <v-card-text>
            <v-row align="center">
              <v-col cols="8" lg="8">
                <div>
                  <v-icon x-large color="#827717">mdi-home-group</v-icon>
                </div>
                <div class="font-weight-normal subtitle-1">
                  {{
                    getters_total_student_campus.faculty > 1
                      ? "Faculties"
                      : "Faculty"
                  }}
                </div>
              </v-col>
              <!-- <v-divider vertical class="my-4"></v-divider> -->
              <v-col
                class="font-weight-bold headline text-center"
                cols="4"
                lg="4"
              >
                {{ getters_total_student_campus.faculty }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="2" lg="2">
        <v-card elevation="9" tile>
          <v-card-text>
            <v-row align="center">
              <v-col cols="8" lg="8">
                <div><v-icon x-large color="teal">mdi-bookshelf</v-icon></div>
                <div class="font-weight-normal subtitle-1">Programs</div>
              </v-col>
              <!-- <v-divider vertical class="my-4"></v-divider> -->
              <v-col
                cols="4"
                lg="4"
                class="font-weight-bold headline text-center"
              >
                {{ getters_total_student_campus.total_program }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="2" lg="2">
        <v-card elevation="9" tile>
          <v-card-text>
            <v-row align="center">
              <v-col cols="8" lg="8">
                <div><v-icon x-large color="#014C78">mdi-home</v-icon></div>
                <div class="font-weight-normal subtitle-1">
                  {{
                    getters_total_student_campus.department > 1
                      ? "Departments"
                      : "Department"
                  }}
                </div>
              </v-col>
              <!-- <v-divider vertical class="my-4"></v-divider> -->
              <v-col
                cols="4"
                lg="4"
                class="font-weight-bold headline text-center"
              >
                {{ getters_total_student_campus.department }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="2" lg="2">
        <v-card elevation="9" tile>
          <v-card-text>
            <v-row align="center">
              <v-col cols="8" lg="8">
                <div>
                  <v-icon x-large color="#8AAB87"
                    >mdi-account-multiple-outline</v-icon
                  >
                </div>
                <div class="font-weight-normal subtitle-1">
                  {{
                    getters_total_student_campus.total_student > 1
                      ? "Students"
                      : "Student"
                  }}
                </div>
              </v-col>
              <!-- <v-divider vertical class="my-4"></v-divider> -->
              <v-col
                cols="4"
                lg="4"
                class="font-weight-bold headline text-center"
              >
                {{ getters_total_student_campus.total_student }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card elevation="9" tile>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn
                v-if="getters_abilities.includes('event_access')"
                color="primary"
                class="mr-4 secondary--text button font-weight-bold"
                @click="dialog = true"
              >
                New Event
              </v-btn>

              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                Today
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                class="mr-4"
                @click="next"
              >
                <v-icon> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 days</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
            <v-dialog v-model="dialog" max-width="900">
              <v-card>
                <v-toolbar flat color="primary" class="secondary--text">
                  <v-toolbar-title>Add New Event</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="mt-5">
                  <v-form ref="newEvent">
                    <v-row justify="space-around">
                      <v-col cols="12" lg="8">
                        <v-text-field
                          class="rounded-0"
                          label="Event Name"
                          v-model="name"
                          :rules="[(v) => !!v || 'Event Name is required...']"
                          prepend-icon="mdi-newspaper-variant-multiple-outline"
                          outlined
                        ></v-text-field>
                        <v-textarea
                          :rules="[(v) => !!v || 'Event Detail is required...']"
                          outlined
                          label="Detail"
                          class="rounded-0"
                          v-model="details"
                          prepend-icon="mdi-message-bulleted"
                          auto-grow
                          name="input-7-1"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="start"
                              label="Start Date"
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              class="rounded-0"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="start"
                            @input="menu2 = false"
                          ></v-date-picker>
                        </v-menu>
                        <v-menu
                          v-model="menu1"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="end"
                              label="End Date"
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              class="rounded-0"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="end"
                            @input="menu1 = false"
                          ></v-date-picker>
                        </v-menu>
                        <p class="subtitle-1">
                          Click To Choose <b>Color</b> Below
                        </p>
                        <v-color-picker
                          width="400"
                          align-content-center
                          v-model="color"
                          :rules="[(v) => v === '' || 'Color is required...']"
                        ></v-color-picker>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>

                <v-card-actions
                  v-if="getters_abilities.includes('event_access')"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    class="
                      secondary--text--text
                      text--lighten-2
                      font-weight-bold
                      text-center
                    "
                    type="submit"
                    width="200"
                    @click.stop="addNewEventBtn"
                    :disabled="disabled"
                  >
                    Create Event
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-sheet>
          <v-sheet min-height="340">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="getEvents"
            ></v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card min-width="390px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-btn
                    v-if="getters_abilities.includes('event_access')"
                    icon
                    @click="deleteEventBtn(selectedEvent.id)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-toolbar-title
                    v-html="selectedEvent.name"
                  ></v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <v-form v-if="currentlyEditing !== selectedEvent.id">
                    {{ selectedEvent.details }}
                  </v-form>
                  <v-expand-transition v-else>
                    <v-form>
                      <v-textarea
                        v-model="selectedEvent.details"
                        outlined
                        label="Add Note"
                      ></v-textarea>
                    </v-form>
                  </v-expand-transition>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="error"
                    text
                    @click="
                      selectedOpen = false;
                      currentlyEditing = null;
                    "
                  >
                    <b>Close</b>
                  </v-btn>
                  <template v-if="getters_abilities.includes('event_access')">
                    <v-btn
                      color="info"
                      v-if="currentlyEditing !== selectedEvent.id"
                      @click.prevent="editEventBtn(selectedEvent)"
                    >
                      <b>Edit</b>
                    </v-btn>
                    <v-btn
                      color="info"
                      v-else
                      @click.prevent="updateEventBtn(selectedEvent)"
                    >
                      <b>Update</b>
                    </v-btn>
                  </template>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { defineComponent, onMounted, reactive, ref, toRefs, watch } from "vue";
  import {
    useActions,
    useGetters /* useMutations */,
  } from "vuex-composition-helpers";
  import NewCalendar from "@/components/Broadcast/NewCalendar";
  export default defineComponent({
    components: { NewCalendar },
    setup(props, context) {
      let reveal = ref(false);
      let campus_id = ref(null);
      let thisCampus = (id) => {
        campus_id.value = id;
      };

      const {
        totalstudentCampus,
        getEvent,
        updateEvent,
        deleteEvent,
        addNewEvent,
      } = useActions([
        "totalstudentCampus",
        "getEvent",
        "updateEvent",
        "deleteEvent",
        "addNewEvent",
      ]);

      const { getters_total_student_campus, getters_event, getters_abilities } =
        useGetters([
          "getters_total_student_campus",
          "getters_event",
          "getters_abilities",
        ]);

      // const { ADD_NEW_EVENT } = useMutations(["ADD_NEW_EVENT"]);

      totalstudentCampus();

      const calendar = reactive({
        today: new Date().toISOString().substr(0, 10),
        focus: new Date().toISOString().substr(0, 10),
        type: "month",
        typeToLabel: {
          month: "Month",
          week: "Week",
          day: "Day",
          "4day": "4 Days",
        },
        color: "",
        disabled: true,
        menu2: false,
        menu1: false,
        name: null,
        details: null,
        start:
          null /*  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                                                                                                    				.toISOString()
                                                                                                    				.substr(0, 10) */,
        end: null /* new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                                                                                                    				.toISOString()
                                                                                                    				.substr(0, 10) */,
        currentlyEditing: null,
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        dialog: false,
        broadcastResponse: false,
      });

      let {
        disabled,
        events,
        focus,
        selectedEvent,
        selectedElement,
        selectedOpen,
        type,
        currentlyEditing,
        details,
        end,
        start,
        color,
        name,
        dialog,
        //broadcastResponse,
      } = toRefs(calendar);

      const getEventColor = (event) => {
        return event.color;
      };

      const setToday = () => {
        focus.value = "";
      };

      const prev = () => {
        context.refs.calendar.prev();
      };

      const next = () => {
        context.refs.calendar.next();
      };

      const showEvent = ({ nativeEvent, event }) => {
        const open = () => {
          selectedEvent.value = event;
          selectedElement.value = nativeEvent.target;
          requestAnimationFrame(() =>
            requestAnimationFrame(() => (selectedOpen.value = true))
          );
        };

        if (selectedOpen.value) {
          selectedOpen.value = false;
          requestAnimationFrame(() => requestAnimationFrame(() => open()));
        } else {
          open();
        }

        nativeEvent.stopPropagation();
      };

      const viewDay = ({ date }) => {
        focus.value = date;
        type.value = "day";
      };

      const getEvents = ({ start, end }) => {
        getEvent({
          start: new Date(`${start.date}T00:00:00`),
          end: new Date(`${end.date}T23:59:59`),
        }).then(() => (events.value = getters_event.value));
      };

      const editEventBtn = (item) => {
        currentlyEditing.value = item.id;
      };

      const deleteEventBtn = (id) => {
        deleteEvent(id).then(() => {
          selectedOpen.value = false;
        });
      };

      const updateEventBtn = (item) => {
        updateEvent(item).then(() => {
          selectedOpen.value = false;
          currentlyEditing.value = null;
        });
      };

      watch(
        () => [name.value, details.value, color.value, start.value, end.value],
        () => {
          if (
            name.value &&
            details.value &&
            color.value &&
            start.value &&
            end.value
          ) {
            disabled.value = false;
          } else {
            disabled.value = true;
          }
        }
      );

      const addNewEventBtn = () => {
        if (context.refs.newEvent.validate()) {
          addNewEvent({
            name: name.value,
            details: details.value,
            start: start.value,
            end: end.value,
            color: color.value,
          }).then(() => {
            context.refs.newEvent.reset();
            dialog.value = false;
          });
        }
      };

      onMounted(() => {
        color.value = "#FF0000";

        /*  window.Echo.private("new-calendar-event").listen(
                            ".App\\Events\\NewCalendarEvent",
                            (e) => {
                              if (e.length > 0) {
                                ADD_NEW_EVENT(e[0]);
                                broadcastResponse.value = true;
                                setTimeout(() => {
                                  broadcastResponse.value = false;
                                }, 5000);
                              }
                            }
                          ); */
      });

      return {
        ...toRefs(calendar),
        viewDay,
        showEvent,
        setToday,
        prev,
        next,
        getEventColor,
        reveal,
        thisCampus,
        campus_id,
        getters_total_student_campus,
        editEventBtn,
        deleteEventBtn,
        updateEventBtn,
        addNewEventBtn,
        getters_abilities,
        getEvents,
      };
    },
  });
</script>
<style>
  .v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
  }
  .card-actions {
    position: absolute;
    bottom: 0;
  }
</style>
