<template>
  <v-snackbar
    color="primary"
    transition="scroll-y-transition"
    elevation="4"
    top
    right
    v-model="newCalendarEvent"
    absolute
  >
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn
        icon
        color="dark-grey"
        text
        v-bind="attrs"
        @click="newCalendarEvent = false"
      >
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  import { ref } from "vue";
  export default {
    setup() {
      const newCalendarEvent = ref(true);
      const text = ref(`New Calendar Event is created`);

      return {
        newCalendarEvent,
        text,
      };
    },
  };
</script>

<style></style>
